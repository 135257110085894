/******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet

Here's where you can start getting into the good stuff.
This size will work on iPads, other tablets, and desktops.
So you can start working with more styles, background images,
and other resources. You'll also notice the grid starts to
come into play. Have fun!

******************************************************************/

/*********************************
Overwrites needed for grid
*********************************/

body.Newsdescription {
    #content .row > #leftcol{width:100%}
    #content .row > #righttcol{display:none;}
}


/******************************************************************
H1, H2, H3, H4, H5 STYLES
******************************************************************/
/*sizing*/
h1, .h1 { font-size: 3em; }
h2, .h2 { font-size: 2em; }
.slideshow_caption .h4 {
	font-size: 1.6em;
}

/*********************
GENERAL STYLES
*********************/

body {}


/*********************
LAYOUT & GRID STYLES
*********************/

.row, .row, body.Home #content #leftcol > div > div, body.home #content #leftcol > div > div, .row-small, body.fullWidth #leftcol > div > div, 
{
	max-width: 1400px;
}
header .row {
	max-width: 1600px;
}
.row-small  {
	max-width: 800px;
}


body.Template-2 {
	header .row {
		max-width: none;
	}
}

// HOME TEMPLATE 4
body.Template-4 {

	#hero, 
	ul.responsive3 > .slick-list > .slick-track > .slick-slide {
		height: 65vh;
	}
}

/*********************
NAVIGATION STYLES
*********************/


.menuWrap {
	width: 95%;
}
.topMenu {	
	text-align: right;
	a.toggleMenu {
		margin-top: 0.75em;
		padding: 2% 4%;
	}
}
	


/*********************
CONTENT STYLES
*********************/
/* content area all inner pages */
#content {
	.row {
	}
}
	

	
	

blockquote {
	&:before {
		
		float: left;
		font-size: 2em;
	}
}





/*********************
HOME STYLES
*********************/

body.Home #content #leftcol > div > div > div.last img

/*********************
LANDING PAGE STYLES
**********************/

body.Landing {
	#content {
		#leftcol {
			> div {
				padding: 4em 0;
			}
		}
	}
}


/*********************
BLOCK LAYOUT
**********************/	

.columnlayout {	
	
    flex-direction: row;

	> div, > li, > article {	width: calc(50% - 2em);}
    article.newsBlock {width: calc(50% - 2em);}
}



/*********************
NEWS LISTING
*********************/
	
div.listingImg { 
	
	float:left; 
	width:30%; 
		
}
	
div.listingTxt { 
	
	float:left; 
	width:70%;
		
}
	
div.caseStudy {
	
	float:left; 
	width: 48%; 
	padding: 1em 0 2em 0; 
	position: relative; 
	margin-right:1.9%
	
}


/*********************
VIDEOS
*********************/

.videoWrapper { 

	margin-left: 0px;
	margin-right: 0px;
	
}

/*************************
THIS IS FOR THE PARALLAX SCROLL
*************************/
#trusticons {

    .parallax-window {
        position: relative;
        padding: 5em 0;
        height: 500px;
        text-align: center;
        ;
        margin-top: 0;
    }
}

/*********************
FOOTER STYLES
*********************/

#footer {
	#copyright {
		.last { text-align: right;}
	}
}

/*
you'll probably need to do quite a bit
of overriding here if you styled them for
mobile. Make sure to double check these!
*/


/*SLIDER*/
/* Bug fix for flashing on Slick slideshow */

/*Full width slider with captions */
    ul.responsive3 {

        .slick-list {

            .slick-track {

                .slick-slide {

                    img {
                        /*height: 520px;*/
						
                    }					
					
                }

            }
        }
		
		.slick-slider .slick-active img{margin-left: -400px;}
    }
	
	

	


/*Fix to prevent last slide flashing*/ .slick-slider .slick-track, .slick-slider .slick-list { -webkit-perspective: 1000px; }



/*************************
STICKY BACK TO TOP
*************************/
    


/*REVIEWS }*/
.gmb-widget-full:not(.holdSlider) {
	column-count: 2;
}

.gmb-limited .gmb-review {
	width: 47%;
}