/******************************************************************
Site Name: 
Author: 

Stylesheet: Super Large Monitor Stylesheet

You can add some advanced styles here if you like. This kicks in
on larger screens.

******************************************************************/

@media screen and (min-width:1440px) {
.mobileOnly {
    display: none !important;
    }

.desktopOnly:not(.button.primary) {
    display: inline-block !important;
    }
}

h1, .h1  { line-height: 1.2;}

/*margins*/
h2, .h2, .gmb-review p{ margin: 0.5em 0;}
#bottomBlocks h4, #bottomBlocks .h4 { margin:  2em 0 1em 0;}

/*sizing*/
h1, .h1 { font-size: 3.2em; }
body.landing .h1 { font-size: 3em; }
h2, .h2 { font-size: 2.3em; }
#accountMenu > a:first-of-type {font-size:1.2em;}


.topMenu .nav > li > a {
    @include bp(papa-screen) {
        font-size: 120%;
    }
}

/*********************
LAYOUT & GRID STYLES
*********************/

.row, body.home #content #leftcol > div > div, body.fullWidth #leftcol > div > div{
    padding: 3em 0;
}
	
/*********************
HEADER STYLES
*********************/

// HOME TEMPLATE 2
body.Template-2 {
	.header {
		#mainMenu {
			/*@include bp(mama-screen) {
				text-align: left;
				width: auto;
				margin-left: 1em;
				margin-top: 0;
				order: 2;
			}*/
		}
		#accountMenu {

            a:first-of-type {
                display: inline-block;
            }
            
        }
	}

	
}


// HOME TEMPLATE 3
body.Template-3 {
	.header {
		#mainMenu {
			display: inline-block;
			position: absolute;
			right: 10%;
			bottom: 20%;
		}

		#accountMenu {
			right: 0%;
            top: unset;
			bottom: 20%;
		}
	}
}

// HOME TEMPLATE 4
body.Template-4 {
    .header {

        .clearHeader {
            position: relative;
            background-color: $white;
            @include transition(all 0.3s ease-in-out);

            #logo {
                width: 200px;
                height: 200px;

                &:before {
                    content: "";
                    flex: none;

                    @include box-shadow($boxshadow);
                    @include fixedCircle(inline-block, 230px, $white);
                    @include transition(all 0.3s ease-in-out);
                }

                &:after {
                    width: 210px;
                    height: 210px;
                    background-size: 210px 210px;
                    @include transition(all 0.3s ease-in-out);
                }
            }
        }

        .darkHeader {
            position: fixed;
            background-color: $white;
            @include box-shadow($boxshadow);
            @include transition(all 0.3s ease-in-out);
            top: 0;

            #logo {
                width: 150px;
                height: 150px;

                &:before {
                    content: "";
                    flex: none;
                    @include box-shadow($boxshadow);
                    @include fixedCircle(inline-block, 175px, $white);
                    @include transition(all 0.3s ease-in-out);
                }

                &:after {
                    width: 160px;
                    height: 160px;
                    background-size: 160px 160px;
                    @include transition(all 0.3s ease-in-out);
                }
            }

            #mainMenu {
                left: 165px;
                @include transition(all 0.3s ease-in-out);
            }
        }

        #mainMenu {
            left: 208px;
            @include transition(all 0.3s ease-in-out);
            display: inline-block;
        }

        .menu-btn {
            display: none;
        }

        #accountMenu {
            right: 0%;
        }
    }
}


/**************************
HERO - Home
***************************/
/*body.Home #hero {max-height:75vh;}*/
body:not(.Home) #hero {
   /*height:300px;*/
    overflow:hidden;
     .block > p img {
            min-width:100%;
            max-width:initial;
            height:auto;
       }
}

body.Home #hero .row .slideshow_caption {
    width: 100%;
}

// HOME TEMPLATE 2, 3
body.Template-2 {
    #hero .row {
        width: 60%;
    }
}

// HOME TEMPLATE 4
body.Template-4{
    #hero .row {
        width: 40%;
    }
}

body.Template-4 .header #accountMenu a {
    display: inline-block;
    margin-left: .5em;  
}
/*********************
NAVIGATION STYLES
*********************/
@media screen and (min-width:1240px) and (max-width:1279px){
    .topMenu {
        .nav {
            > li {
                padding: 0 0.75em;
            }
        }
    }
}

@media screen and (min-width:1280px) {
    .topMenu {
        .nav {
            > li {
                padding: 0 1em;
            }
        }
    }
}




    /*********************
CONTENT STYLES
*********************/

    #content #leftcol > div > .first {
        padding: 0 2em;
    }


    /*********************
BLOCK LAYOUT
**********************/


    .columnlayout {

        > div, li {
        }
        /*Top row needs border adding seperatley to prevent double borders*/
        > div:nth-child(4), li:nth-child(4) {
        }
    }


    #bottomBlocks {
        .block {
            margin-bottom: 0em;
        }
    }

    /* LEFT BLOCKS - Mission statement*/
    #homeMission {
        .row {
            height: 60vh;
            display: flex;
            align-items: center;
        }
    }


    /*Full width slider */
    ul.responsive3 {

        .slick-list {
            .slick-track {
                .slick-slide {

                    img {
                    }
                }
            }
        }
    }

