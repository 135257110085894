/******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet Landscape

Needed to change the menu styling from mobile to desktop

******************************************************************/
.mobileOnly, .button.primary.desktopOnly {display:inline-block!important;}
.desktopOnly:not(.button.primary) {display:none!important;}
a.mobileOnly {display:none!important;}

/*sizing*/
h1, .h1 { font-size: 2.4em; }
h2, .h2 { font-size: 2em; }
.bigger, h3, .h3, [data-control] {font-size:1.2em;}
#message {font-size:1em;}

/*************************
Colours
*************************/

.white { color: $white; }


/*********************
LAYOUT & GRID STYLES
*********************/
.row, body.home #content #leftcol > div > div, body.fullWidth #leftcol > div > div{
    padding: 2em 0;
}

/* Reduce padding on some rows*/
#breadcrumbs .row {padding: 1em 0;}

/*Increase padding content some rows */

/* Restrict width on some rows and content areas that behave like rows*/

body.Template-4 {

    #hero,
    ul.responsive3 > .slick-list > .slick-track > .slick-slide {
        height: 75vh;
    }
}

body.Template-4 #hero .row {
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    width: 95%;
    top: 47%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translate3d(-50%, -50%, 0);
}
		
/*********************
SPRITE
*********************/
		

    #logo {
        width:90px; height: 91px;
	    background-size: 90px 91px;
	}

/*********************
HEADER STYLES
*********************/

body.Home .header {
	/*height: 112.09px;*/
}

.header {

	    .row {
		    position: relative;
            min-height: 110px;
	    }

	    #logo, #accountMenu {
            transform: none;
	    }

        #logo {
            left: 0%;
            top:0.5em;
        }

	    #accountMenu {
			top: 6%;
            right:0;
	    }

        #mainMenu {
            display: inline-block;
            position: absolute;
            right: 0%;
            bottom:6%;
        }
     		
}


// HOME TEMPLATE 2
body.Template-2 {
    .header {
        #mainMenu {
            text-align: left;
            width: auto;
            margin-top: 0;
            @include center(false, true);
            left:105px;
        }

        .row {
            flex-wrap: no-wrap;
        }

        #accountMenu {
            max-width: none;
            @include center(false, true);

            a:first-of-type {
                display: block;
                margin-right: 0.5em;
            }
            
        }

        .menu-btn {
            display: none;
            right: 0%;
            left: unset;
            bottom: 0%;
            top: unset;
        }
    }
}

// HOME TEMPLATE 3

body.Template-3 {
    .header {
        #accountMenu {
            transform: none;
			top: 10%;
            right:0;
	    }
    }
}


// HOME TEMPLATE 4
body.Template-4 {
    .header {
        .row {
            min-height: 90px;
        }

        #logo, #accountMenu {
            transform: none;
        }

        #logo {
            left: 0%;
            top: 1em;
        }

        #accountMenu {
            @include center(false, true);
            right: 7%;
        }

        #mainMenu {
            right: unset;
            left: 130px;
            @include center(false, true);
            @include transition(all 0.3s ease-in-out);
            display: none;
        }

        .menu-btn {
            display: inline-block;
            right: 0;
            position: absolute;
            top: 50%;
            /* -webkit-transform: translate3d(0, -50%, 0); */
            -ms-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0);
            color: $black;
            left: auto;
        }
    }
}


/**************************
HERO - Home
***************************/

body:not(.home) #hero {

	.block {
       img {max-width:1200px;}
    }
}/*hero*/


/*********************
NAVIGATION STYLES
*********************/




.topMenu {
    text-align: right;
    margin-left: auto;
    margin-right: auto;
    float: right;
    clear: none;


    a.toggleMenu {
        display: none;
    }

    .nav {
        position: relative;
        width: auto;
        border: 0;
        display:inline;


        > li {
            display: inline-block;
            padding: 0 0.5em;

            > a {
                outline: none;
                &:hover, &:focus {
                    background: transparent;
                }

                /*&.parent:after {
                    content: "\f107";
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 300;
                    display: block;
                    float: right;
                    font-size: large;
                    padding-left: 0.3em;
                    line-height: 1.5;
                }*/

            }
            /*Prominent contact link*/
            &:first-child {
                display: none;
            }

            &:last-child {
                display: none;
            }

            /*&:last-child {
                > a {
                    background: $color-secondary;
                    @include border-radius($small-border-radius);
                    padding: 0.5em 1em;
                    @include transition(all 0.5s ease-in-out);

                    &:hover, &:focus, &:active, &.activerootmenulink {
                        background: darken($color-secondary, 15%);
                        @include transition(all 0.5s ease-in-out);
                        color: $white;
                    }
                }
            }*/
            /*turn some of the main nav items OFF for public*/
            /*&:first-child > a{
				display:none;
			}
			
			&:nth-child(6) {
				display:none;
			}
			&:nth-child(7) {
				display:none;
			}
			*/
        }

        li {
            position: relative;

            a {
                padding: 0.25em 0 0.5em 0em;
                background: none;
                border-bottom: none;


                &.parent:before {
                    display: none;
                }
            }
            /* highlight current page */
            a.activerootmenulink {
                color: $color-five;
            }

            &:first-child {
            }

            &:last-child {
            }
            /*
			plan your menus and drop-downs wisely.
			*/
            ul,
            ul.sub-menu,
            ul.children {
                position: absolute;
                z-index: 9999;
                left: -9999px;
                border-top: 2px solid $color-primary;

                li {

                    a {
                        padding: 0.5em 0.5em;
                        display: block;
                        width: 250px;
                        border-top: none;
                        border-radius: 0;
                        margin-right: 0;


                        &:hover,
                        &:focus {
                            border-top: none;
                        }

                        &:link {
                        }
                    }

                    &:first-child {
                    }

                    &:last-child {

                        a {
                        }
                    }
                    /*
					if you need to go deeper, go nuts
					just remember deeper menus suck
					for usability.
					*/
                    ul {
                        border-top: none;
                    }
                }
            }
            /* showing sub-menus */
            &:hover ul {
            }
        }
        /* end .menu ul li */
    }
    /* end .nav */
} /* end .topMenu */

.nav > li.hover > ul { left: 0;}
.nav li li ul { left: -9999px; z-index: 99; position:absolute; }
.nav li li.hover ul { left: 100%; top: 0; z-index: 99; position:absolute; }

/* active state on home - not sure where else to put this for now*/

// HOME TEMPLATE 2
body.Template-2 {
    .topMenu {
        float: left;
        text-align: left;

        .nav {
            margin: 0 auto;

            > li {
                display: inline-block;
                padding: 0 1em;

                > a, > a.parent {
                    position: relative;
                    background: transparent;
                    padding: 0.25em 0em;

                    &:hover, &:focus {
                        background: none;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        bottom: 0;
                        left: 0;
                        background-color: $white;
                        visibility: hidden;
                        -webkit-transform: scaleX(0);
                        transform: scaleX(0);
                        @include transition(all 0.3s ease-in-out 0s);
                    }

                    &:hover:after, &.activerootmenulink:after {
                        visibility: visible;
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                    }
                }
            }
        }
    }

    .darkHeader .topMenu .nav > li > a:after {background-color: $color-primary; @include transition(all 0.3s ease-in-out 0s);}

    .nav > li.hover > ul {
        left: 0;
    }

    .nav li li ul {
        left: -9999px;
        z-index: 99;
        position: absolute;
    }

    .nav li li.hover ul {
        left: 100%;
        top: 0;
        z-index: 99;
        position: absolute;
    }
}

// HOME TEMPLATE 3
body.Template-3 {

    .clearHeader {

        .topMenu .nav > li > a.activerootmenulink:after, .topMenu .nav > li > a:after, .topMenu .nav > li > a.parent:after {
            background-color: $white;
        }
    }

    .topMenu {

        .nav {

            > li {
                > a, > a.parent {
                    position: relative;
                    background: transparent;
                    padding: 0.25em 0em;

                    &:hover, &:focus {
                        background: none;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        bottom: 0;
                        left: 0;
                        background-color: $color-secondary;
                        visibility: hidden;
                        -webkit-transform: scaleX(0);
                        transform: scaleX(0);
                        @include transition(all 0.3s ease-in-out 0s);
                    }

                    &:hover:after, &.activerootmenulink:after {
                        visibility: visible;
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                    }
                }
            }

            
        }
    }
}



/*********************
CONTENT STYLES
*********************/

.col-2 {
    > div, > li {
        grid-column: span 1;
    }
}

p.quote {
    margin-top: 1em;
}
/*********************
BLOCK LAYOUT
**********************/	


.columnlayout {	
	> div, > li, > article {width: calc(33% - 1em);}
    article.newsBlock {width: 25%; }
}
#gallery li {width: 32%;}



/*********************
FOOTER STYLES
*********************/

     body.Template-2 {
        #bottomBlocks {
            .respond-form {padding-top:1em;}
        }
        #footer {
            .last {text-align:right;}
        }
    }

     body.Template-3 {
        #footer {
            .last {text-align:right;}
        }
    }

    /*Bottom Blocks*/

    #bottomBlocks .block {
        margin-bottom: 2em;
        margin: 0 auto;
    }

    /*layout needed for list items */
    #bottomBlocks #block_26 {

        ul {
           
            li {
                display:inline-block;
                width: 48%;
                margin-bottom: 0.5em;

            }
        }

    }

/* ================================================ */
/* SLIDESHOWS */
/* ================================================ */  

/*Full width slider */
body.Home #hero {
	overflow:hidden;
	.slick-slider { }
}

ul.responsive3 {
	max-height: none;
	.slick-list {
			
		.slick-track {
				
			.slick-slide {
				/*height:500px;*/
				
				&:before {background-color: rgba(0, 0, 0, 0.45); }
				
				img {
                        /*height: 420px;*/
                    }	
				.slideshow_caption { text-align:left; /*margin: 0 auto;padding:2em; max-width:50%;*/ }
			}
				
		}
			
	}

    .slick-slider .slick-active img{ margin-left: 0;}

    .slick-prev, .slick-next { top: 50%; }
    .slick-next { right: 0px; }
    .slick-prev { left: 0; }
    .slick-slider .slick-track, .slick-slider .slick-list { -webkit-perspective: 1000px; }
}

// HOME TEMPLATE 2
body.Template-2, body.Template-3, body.Template-4 {
   .slick-list {
			
		.slick-track {
				
			.slick-slide {
	
				.slideshow_caption { text-align:center; }
			}
				
		}
    }
}



/*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        height: 50px;
        width: 50px;
    }

/*********************
Sticky CTA
*********************/

#stickyCTA {
	 
}